import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import WebsiteNavigation from "./pages/util/WebsiteNavigation";
import PrivacyHTML from "./legal/PrivacyHTML";
import TermsAndConditionsHTML from "./legal/TermsAndConditionsHTML";
import HomePage from "./pages/HomePage";

function App() {
  const last_updated = new Date(2024, 0, 1);
  const website_url = 'https://youtube_trim.ai';
  const company_name = "Smart YouTube AI Trimmer";

  return (
   <Routes>
      <Route path='/' element={<WebsiteNavigation/>}>
        <Route index element={<HomePage/>}/>
        <Route path='/terms-and-conditions' element={<TermsAndConditionsHTML last_updated={last_updated} website_url={website_url} company_name={company_name}/>}/>
        <Route path='/privacy-policy' element={<PrivacyHTML last_updated={last_updated} website_url={website_url} company_name={company_name}/>}/>
      </Route>
   </Routes>
  );
}

export default App;
