import { Box, Grid, Paper, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import baseURL from "./baseURL";

function QuickStats() {

    const [quickStats, setQuickStats] = useState(null)

    const api_call = () => {
        axios.get(`${baseURL}/quick-stats`, { withCredentials: true }).then((response) => {
            console.log("QUICK STATS", response)
            setQuickStats(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        api_call();
    }, [axios]);

    const columns = [
        { field: 'days_since_published', headerName: 'Days Published', width: 130 },
        { field: 'visits', headerName: 'Site Visits', width: 130 },
        { field: 'youtube_videos', headerName: 'Videos Created', width: 130 },
        { field: 'waitlist_users', headerName: 'Waitlisted Users', width: 130 },
    ];

    return ( <>
        <h2>Quick Stats</h2>
        <Grid container  spacing={2} justifyContent='space-evenly'>
            {columns.map((obj) => {return(
                <Grid item xs={6} sm={1}>
                    <Stack style={{textAlign:'center'}} alignItems='center' spacing={2}>
                        <Paper elevation={2} style={{minWidth:'100px', height:'100px', border:'1px solid grey'}}>
                            {quickStats == null ? null : (<h2>{quickStats[obj.field]}</h2>)}
                        </Paper>
                        <Box style={{textAlign:'center', fontWeight:'bold'}}>{obj.headerName}</Box>
                    </Stack>
                </Grid>)})}
        </Grid>
    </>);
}

export default QuickStats;
