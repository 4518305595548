// src/components/DarkModeToggle.js
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React, { createContext, useMemo, useState } from 'react';

const ToggleDarkModeContext = createContext();

export default function DarkModeThemeProvider({ children }) {
    const [mode, setMode] = useState('dark');
    const toggleColorMode = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    }

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
                typography: {
                    button: {
                      textTransform: 'none'
                    }
                  },
            }),
        [mode],
    );

    return (
        <ToggleDarkModeContext.Provider value={toggleColorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ToggleDarkModeContext.Provider>
    );
}

export { DarkModeThemeProvider, ToggleDarkModeContext };
