// src/App.js
import React, { useState, useContext } from 'react';
import {
  AppBar,
  Container,
  CssBaseline,
  Grid,
  Toolbar,
  Typography,
  Paper,
  TextField,
  Button,
  Stack,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  IconButton,
  CircularProgress,
  LinearProgress,
  
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

import axios from 'axios';

import QuickStats from '../QuickStats';
import JoinWaitlistComponent from '../components/JoinWaitlistComponent';
import baseURL from '../baseURL';



const getYoutubeVideoId = (url) => {
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
};

const VideoPlayer = ({sourceUrl, videoProgress}) => {
    console.log(sourceUrl, 'changed')

    if (videoProgress != 100) {
        return (
        <div style={{width:'100%'}}>
            Precent Complete: {videoProgress}%
            <LinearProgress variant="determinate" value={videoProgress} />
        </div>
        )
    }

    return( sourceUrl != '' ? (<video controls width="100%" height="auto">
        <source src={sourceUrl} type="video/mp4" />
        Your browser does not support the video tag.
        </video>) : null
    )
}

function HomePage() {
    const [activeStep, setActiveStep] = useState(0);
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [videoId, setVideoId] = useState('');
    const [generatingVideos, setGeneratingVideos] = useState(false);
  
    const [finished_preprocessing, setFinishedPreprocessing] = useState(false);
    const [video_url_1, setVideoUrl1] = useState('');
    const [video_url_2, setVideoUrl2] = useState('');
    const [video_1_progress, setVideo1Progress] = useState(0);
    const [video_2_progress, setVideo2Progress] = useState(0);
    

    const handleUrlChange = (event) => {
      setYoutubeUrl(event.target.value);
    };
  
    const handleEmbedClick = () => {
      const id = getYoutubeVideoId(youtubeUrl);
      if (id) {
        setVideoId(id);
        setActiveStep(1);
      } else {
        alert('Invalid YouTube URL');
      }
    };
  
    const handleGenerateClick = () => {
      console.log('Generating Videos', videoId);
      setGeneratingVideos(true);
      startInterval();
    };

    const startInterval = () => {
      const intervalId = setInterval(() => {
        axios.post(`${baseURL}/generate-videos`, {youtube_id: videoId}).then(response => {
          console.log(response.data);
          setVideoUrl1(response.data.video_1_src);
          setVideoUrl2(response.data.video_2_src);
          setVideo1Progress(Math.floor(response.data.video_1_progress));
          setVideo2Progress(Math.floor(response.data.video_2_progress));
          setFinishedPreprocessing(response.data.finished_preprocessing);
          if (response.data.status === 'finished') {
            clearInterval(intervalId); // Stop the interval if the status is 'finished'
          }
        }).catch((error) => {
          clearInterval(intervalId); // Stop the interval if the status is 'finished'
  
        });
      }, 1000); // Send request every 1 second
    };

    
    return (
        <Stack spacing={2}>
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <QuickStats/>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Stack spacing={2}>
            <h2>
              AI YouTube Shorts Trimmer
            </h2>
            <p>
              This site uses AI to find the most engaging moments for your video to be used as a YouTube Short.
            </p>

            <Stepper orientation="vertical" activeStep={activeStep}>
                <Step expanded={true}>
                  <StepLabel>Add YouTube Video</StepLabel>
                  <StepContent>
                    <Stack spacing={2}>
                        <Box display='flex' alignItems='center'>
                          <TextField
                            label="YouTube Video URL"
                            variant="outlined"
                            fullWidth
                            value={youtubeUrl}
                            onChange={handleUrlChange}
                          />
                          <IconButton sx={{ml:2}} onClick={() => {setYoutubeUrl(''); setVideoId('');}}>
                            <ClearIcon/>
                          </IconButton>
                        </Box>
                      
                        <Button fullWidth variant="contained" onClick={handleEmbedClick}>
                          Add Video
                        </Button>
                    {videoId && (
                      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', marginTop: '20px' }}>
                        <iframe
                          title="YouTube Video"
                          width="100%"
                          height="100%"
                          src={`https://www.youtube.com/embed/${videoId}`}
                          frameBorder="0"
                          allowFullScreen
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        ></iframe>
                      </div>
                    )}
                    </Stack>
                  </StepContent>
                </Step>
                <Step expanded active>
                  <StepLabel>Generate Video</StepLabel>
                  <StepContent>
                    <Button variant='contained' fullWidth onClick={handleGenerateClick}>Generate</Button>
                  </StepContent>
                </Step>
            </Stepper>
          </Stack>
        </Paper>

     


        {generatingVideos && <Paper padding={2} elevation={3}>
          <Stack padding={2} spacing={2} alignItems='center' justifyContent='center' width='100%'>
            <h2>
              Generated Videos
            </h2>
            <p>(This process can take upto 5 mintues to complete)</p>
            {finished_preprocessing == false && (
                <div style={{width:'100%'}}>
                    <p>Running AI Module Preprocessing</p>
                    <LinearProgress />
                </div>)}
            <h3>Video 1</h3>
            <VideoPlayer sourceUrl={video_url_1} videoProgress={video_1_progress}/>
            <h3>Video 2</h3>
            <VideoPlayer sourceUrl={video_url_2} videoProgress={video_2_progress}/>
          </Stack>
        </Paper> }


        <JoinWaitlistComponent/>

        <Paper elevation={3} >
          <h2 style={{textAlign:'center'}}>About Us</h2>

          <Stack spacing={2} direction='row' paddingX={4} paddingBottom={4} alignItems='center' justifyContent='center'>
            <SmartToyIcon sx={{ fontSize: 80 }}/>
            <div>
              <p>Hi my name is Snipply! I am the software engineer that built this website. I used to work at Meta (Facebook) and now I am building my own software. I'm taking the time to build products I find most exciting and seeing where it goes! I hope you find this tool useful (even in it's early stages). If you do, let's build a community and start something amazing.</p>
            </div>
          </Stack>
        </Paper>


        <Paper padding={2} elevation={3}>
          <Stack padding={2} spacing={2}>
            <h2 style={{ textAlign: 'center' }}>Generated Video Examples</h2>
            <p>Here are two different examples of how our model can be used to generate the most engaging 45 seconds from a YouTube video that can then be used to make a YouTube Short. Here are two examples from the Joe Rogan Podcast and the BBC Planet Earth.</p>
            <Grid container spacing={2} justifyContent='space-evenly' paddingRight={2}>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} alignItems='center' justifyContent='center' >
                  <h3>Original Video</h3>
                  <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                    <iframe
                      title="YouTube Video"
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      src={'https://www.youtube.com/embed/LxX98Jo4E40'}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <h3>Generated Video</h3>
                  <p>Projected Most Engaging Clip For YouTube Shorts</p>
                  <VideoPlayer sourceUrl={"https://youtube-shorts-bucket.s3.amazonaws.com/BasicModule/LxX98Jo4E40/Hulk Hogan on Facing The Rock at Wreslemania 18/highlight_3214.mp4"} videoProgress={100}/>
                  <p>2nd Most Engaging Clip For YouTube Shorts</p>
                  <VideoPlayer sourceUrl={"https://youtube-shorts-bucket.s3.amazonaws.com/BasicModule/LxX98Jo4E40/Hulk Hogan on Facing The Rock at Wreslemania 18/highlight_3457.mp4"} videoProgress={100}/>

                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={2} alignItems='center' justifyContent='center'>
                  <h3>Original Video</h3>
                  <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
                    <iframe
                      title="YouTube Video"
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      src={'https://www.youtube.com/embed/PvWLbK_mNw0'}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
              
                  <h3>Generated Video</h3>
                  <p>Projected Most Engaging Clip For YouTube Shorts</p>
                  <VideoPlayer sourceUrl={"https://youtube-shorts-bucket.s3.amazonaws.com/BasicModule/PvWLbK_mNw0/Polar Bear vs Walrus | Planet Earth | BBC Earth/highlight_-1618.mp4"} videoProgress={100}/>
                  <p>2nd Most Engaging Clip For YouTube Shorts</p>
                  <VideoPlayer sourceUrl={"https://youtube-shorts-bucket.s3.amazonaws.com/BasicModule/PvWLbK_mNw0/Polar Bear vs Walrus | Planet Earth | BBC Earth/highlight_-1494.mp4"} videoProgress={100}/>
                </Stack> 
              </Grid>
            </Grid>
          </Stack>
        </Paper>
        </Stack>
    );
}

export default HomePage;