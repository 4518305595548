import React, { useState } from 'react';
import { Paper, Stack, TextField, Button } from '@mui/material';
import axios from 'axios';
import baseURL from '../baseURL';

const JoinWaitlistComponent = () => {
  const [waitlistEmail, setWaitlistEmail] = useState('');
  const [isJoined, setIsJoined] = useState(false);

  const handelWaitlistEmailChange = (event) => {
    setWaitlistEmail(event.target.value);
  };

  const handelJoinWaitlist = () => {
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(waitlistEmail)) {
      alert('Please enter a valid email address.');
      return;
    }

    // Mock submission to a server
    // Replace with your actual submission logic
    axios.post(`${baseURL}/waitlist`, {
        email: waitlistEmail
    }).then((response) => {
        setIsJoined(true);
    });
  };

  return (
    <Paper padding={2} elevation={3}>
      <Stack padding={2} spacing={2} width='100%'>
        <h2 style={{ textAlign: 'center' }}>Join The Waitlist</h2>
        <p>
          Hi there! We are currently in beta and would love to have you join our waitlist. We will notify you when we are ready to launch.
          You will be the first set of users to get information on any improvement and updates to our platform.
        </p>
{/* 
        <p>Planned new features:</p>
        <ul style={{marginBottom:14, lineHeight:'200%'}}>
          <li>Profile and login to see all the videos you have previously generated</li>
          <li>Ability to make a custom module for your YouTube Channel</li>
          <li>Modules bases on popular YouTube creators</li>
          <li>Speed Improvements</li>
        </ul> */}

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={waitlistEmail}
          onChange={handelWaitlistEmailChange}
        />

        <Button
          variant='contained'
          fullWidth
          onClick={handelJoinWaitlist}
          style={{ backgroundColor: isJoined ? 'green' : undefined }}
          disabled={isJoined}
        >
          {isJoined ? 'User has joined the waitlist' : 'Join Waitlist'}
        </Button>
      </Stack>
    </Paper>
  );
};

export default JoinWaitlistComponent;
