import React, { useContext } from "react";

import {
    AppBar,
    Container,
    CssBaseline,
    Toolbar,
    Box,
    IconButton,
    Button,
    useMediaQuery,
    Stack,
    Typography,
} from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

import { ToggleDarkModeContext } from '../../DarkModeThemeProvider';
import { Link, Outlet } from "react-router-dom";
import { useTheme } from "@emotion/react";


function WebsiteNavigation() {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleColorMode = useContext(ToggleDarkModeContext);

    return (

        <div>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar >
                    <Container maxWidth='md'>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <div style={{marginLeft:12}}>Beta Version</div>
                            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <h2>Smart YouTube AI Trimmer</h2>
                            </Link>
                            <IconButton style={{marginRight:12}} onClick={() => { toggleColorMode() }}><WbSunnyIcon /></IconButton>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>

            <Container maxWidth='md' sx={{ my: 4 }}>
                <Outlet />
            </Container>

            <Container maxWidth='md'>
                <Box marginY={4} display='flex' alignItems='center'>
                    {isSmallScreen ? (
                        <Stack spacing={2} padding={4} justifyContent='center' textAlign='center'>
                            <Typography variant="p" component="div" sx={{ marginRight: 2 }}>
                                Copyright © 2024 Smart YouTube AI Trimmer
                            </Typography>
                            <Button size='small' variant='outlined' component={Link} to='/terms-and-conditions' sx={{ marginRight: 2 }}>Terms of Service</Button>
                            <Button size='small' variant='outlined' component={Link} to='/privacy-policy' sx={{ marginRight: 2 }}>Privacy Policy</Button>
                        </Stack>) : (<>
                            <Typography variant="p" component="div" sx={{ marginRight: 2 }}>
                                Copyright © 2024 Smart YouTube AI Trimmer
                            </Typography>
                            <Button size='small' variant='outlined' component={Link} to='/terms-and-conditions' sx={{ marginRight: 2 }}>Terms of Service</Button>
                            <Button size='small' variant='outlined' component={Link} to='/privacy-policy' sx={{ marginRight: 2 }}>Privacy Policy</Button>
                        </>)}
                </Box>
            </Container>

        </div>
    );
}

export default WebsiteNavigation;
